import React, { memo, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { AdzilyLogo } from 'assets/svg-components';
import { useHistory } from 'react-router-dom';
// import LanguageButton from 'components/LanguageButton/LanguageButton';
import paths from 'routes/paths';
import useStyles from './styles';

function Navbar() {
  const [scrolled, SetScrolled] = useState(false);
  const classes = useStyles({ scrolled });
  const history = useHistory();

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -40) {
      SetScrolled(true);
    } else {
      SetScrolled(false);
    }
  }, []);

  return (
    <>
      <Toolbar />
      <AppBar position="fixed" className={classes.root}>
        <div className={classes.flexContainer}>
          <div className={classes.flex}>
            <IconButton
              className={classes.logoButton}
              onClick={() => history.push(paths.home.path)}
            >
              <AdzilyLogo className={classes.logo} />
            </IconButton>
            <Toolbar className={classes.toolbar}>{/* <LanguageButton /> */}</Toolbar>
          </div>
        </div>
      </AppBar>
    </>
  );
}

export default memo(Navbar);
