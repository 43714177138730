import React from 'react';
import Lottie from 'react-lottie';

import Grid from '@material-ui/core/Grid';
import { Box, Link, Typography } from '@material-ui/core';

import { MdCall } from 'react-icons/md';
import { TfiWorld } from 'react-icons/tfi';
import { BsWhatsapp } from 'react-icons/bs';
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

import contactLottie from 'assets/lottie/contact_lottie.json';
import socialLottie from 'assets/lottie/social_lottie.json';
import AdzilyLogo from 'assets/Adzily-logo-large.png';

import useStyles from './styles';

function Dashbord() {
  const classes = useStyles();

  return (
    <>
      <svg width="0" height="0">
        <linearGradient id="gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#8e4398" offset="5%" />
          <stop stopColor="#663399" offset="95%" />
        </linearGradient>
      </svg>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ marginTop: '-16px' }}
      >
        <Grid item xs={12}>
          <Box className={classes.mainBox}>
            <Box className={classes.logoContainer}>
              <Link
                href="https://adzily.com/"
                target="_blank"
                rel="noopener"
                underline="none"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '24px',
                }}
              >
                <img src={AdzilyLogo} width="100%" alt="Adzily Logo" />
              </Link>
            </Box>
            <Typography align="center" className={classes.message}>
              Adzily is a digital out-of-home advertising company that combines innovation and
              digital solutions through a dynamic platform in the transformation of Egypt&apos;s
              DOOH industry.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={`${classes.mainBox} ${classes.subBox}`}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: contactLottie,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width="60%"
              height="100%"
              isClickToPauseDisabled
            />
            <Typography variant="h3" gutterBottom className={classes.title}>
              Contact Us
            </Typography>
            <Grid
              container
              direction="row"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                width: '80%',
              }}
            >
              <Grid item xs={3}>
                <a href="tel:+201507979703">
                  <Box className={classes.icons}>
                    <MdCall style={{ fill: 'url(#gradient)' }} />
                  </Box>
                </a>
              </Grid>
              <Grid item xs={3}>
                <a href="https://wa.me/201507979704">
                  <Box className={classes.icons}>
                    <BsWhatsapp style={{ fill: 'url(#gradient)' }} />
                  </Box>
                </a>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={`${classes.mainBox} ${classes.subBox}`}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: socialLottie,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width="60%"
              height="100%"
              isClickToPauseDisabled
            />
            <Typography variant="h3" gutterBottom className={classes.title}>
              Social Media
            </Typography>
            <Grid
              container
              direction="row"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                width: '80%',
              }}
            >
              <Grid item xs={3}>
                <Typography align="center" gutterBottom style={{ fontSize: '1rem' }}>
                  <Link
                    href="https://www.facebook.com/Adzily.Ads/"
                    target="_blank"
                    rel="noopener"
                    underline="none"
                    style={{ color: '#8e4398' }}
                  >
                    <Box className={classes.icons}>
                      <FaFacebookF style={{ fill: 'url(#gradient)' }} />
                    </Box>
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="center" gutterBottom style={{ fontSize: '1rem' }}>
                  <Link
                    href="https://www.linkedin.com/company/adzily/"
                    target="_blank"
                    rel="noopener"
                    underline="none"
                    style={{ color: '#8e4398' }}
                  >
                    <Box className={classes.icons}>
                      <FaLinkedinIn style={{ fill: 'url(#gradient)' }} />
                    </Box>
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="center" gutterBottom style={{ fontSize: '1rem' }}>
                  <Link
                    href="https://www.instagram.com/adzily/"
                    target="_blank"
                    rel="noopener"
                    underline="none"
                    style={{ color: '#8e4398' }}
                  >
                    <Box className={classes.icons}>
                      <FaInstagram style={{ fill: 'url(#gradient)' }} />
                    </Box>
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="center" gutterBottom style={{ fontSize: '1rem' }}>
                  <Link
                    href="https://adzily.com/"
                    target="_blank"
                    rel="noopener"
                    underline="none"
                    style={{ color: '#8e4398' }}
                  >
                    <Box className={classes.icons}>
                      <TfiWorld style={{ fill: 'url(#gradient)' }} />
                    </Box>
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashbord;
