import { put, call, takeLatest } from 'redux-saga/effects';
import { loadNetwork, receivedNetwork } from 'redux/features/network.slice';
import snackbar from 'utils/snackbar';
import API from 'utils/api';

const fetchNetwork = () =>
  API.get('', {
    params: {
      action: 'insights',
    },
  });

function* handleLoadNetwork() {
  try {
    const resp = yield call(fetchNetwork);
    yield process.env.NODE_ENV === 'development' && console.log(resp);
    const screens = yield parseInt(resp.data?.screens?.replace('+', ''), 10);
    const visitorsPerDay = yield 150;

    yield put(
      receivedNetwork({
        network: resp.data?.network,
        stats: {
          screens,
          visitors: (screens / 4) * 30 * visitorsPerDay,
          avgAd: 1500,
        },
      }),
    );
  } catch (error) {
    yield console.log(error.response);
    yield console.log(error.toString());
    yield snackbar.error('Oops! Something went wrong.');
  }
}

export default function* watchNetwork() {
  yield takeLatest(loadNetwork.toString(), handleLoadNetwork);
}
