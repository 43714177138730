import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Dashbord from 'pages/Dashbord/Dashbord';
// import FAQs from 'pages/FAQs/FAQs';
import Layout from 'components/Layout/Layout';
// import Networks from 'pages/Networks/Networks';
import paths from './paths';

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path={paths.home.path}>
          <Layout>
            <Dashbord />
          </Layout>
        </Route>
        {/* <Route exact path={paths.faqs.path}>
          <Layout gradientBG>
            <FAQs />
          </Layout>
        </Route>
        <Route exact path={paths.faqs.network}>
          <Layout gradientBG>
            <Networks />
          </Layout>
        </Route> */}
        <Redirect to={paths.home.path} />
      </Switch>
    </Router>
  );
}

export default Routes;
