import { gradientBgHeight, gradientBgHeightScreenLarge } from 'config/constants';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  contentContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: 1060,
    padding: spacing(2, 3),
    margin: spacing(1, 0, 0),
    '@media (min-width: 400px)': {
      margin: spacing(1, 1, 0),
    },
    [breakpoints.up('md')]: {
      margin: spacing(3, 7),
    },
  },
  gradientBG: {
    height: props => (props.gradientBG ? '100vh' : gradientBgHeight),
    borderBottomLeftRadius: props => (props.gradientBG ? 0 : '50% 75px'),
    borderBottomRightRadius: props => (props.gradientBG ? 0 : '50% 75px'),
    [breakpoints.up('sm')]: {
      height: props => (props.gradientBG ? '100vh' : gradientBgHeightScreenLarge),
      borderBottomLeftRadius: props => (props.gradientBG ? 0 : '50% 40px'),
      borderBottomRightRadius: props => (props.gradientBG ? 0 : '50% 40px'),
    },
    backgroundImage: `linear-gradient(to right, ${palette.secondary.dark}, ${palette.primary.main} 90%)`,
    position: props => (props.gradientBG ? 'fixed' : 'absolute'),
    zIndex: '-1',
    width: '100%',
    top: 0,
    overflow: 'hidden',
    transition: 'all 500ms',
  },
}));
