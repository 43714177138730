import { createAction, createReducer } from '@reduxjs/toolkit';

export const changeLanguage = createAction('language/changeLanguage');

const initialState = localStorage.getItem('locale') || 'en';

export default createReducer(initialState, {
  [changeLanguage]: state => {
    const language = state === 'ar' ? 'en' : 'ar';
    localStorage.setItem('locale', language);
    return language;
  },
});
