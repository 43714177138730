import React, { useState, useEffect } from 'react';
import Navbar from 'components/Navbar/Navbar';
import ErrorBoundary from 'utils/ErrorBoundary';
import { useDispatch } from 'react-redux';
import { loadNetwork } from 'redux/features/network.slice';
import useStyles from './styles';

function Layout({ children, gradientBG }) {
  const classes = useStyles({ gradientBG });
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadNetwork());
  }, [dispatch]);

  return (
    <>
      <Navbar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      <div className={classes.flexContainer}>
        <div className={classes.gradientBG} />

        <div className={classes.contentContainer}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
    </>
  );
}

export default Layout;
