import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  button: {
    width: '100%',
  },
  btnContainer: {
    paddingTop: spacing(4),
    [breakpoints.up('sm')]: {
      paddingTop: spacing(8),
    },
  },
  mainBox: {
    textTransform: 'uppercase',
    backgroundColor: 'white',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: spacing(2),
    padding: spacing(1),
    '&:first-child': {
      padding: spacing(3),
    },
    [breakpoints.up('sm')]: {
      margin: spacing(1),
    },
  },
  subBox: {
    [breakpoints.up('sm')]: {
      height: '300px',
    },
    [breakpoints.up('md')]: {
      height: '350px',
    },
  },
  logoContainer: {
    [breakpoints.up('sm')]: {
      width: '90%',
    },
    [breakpoints.up('md')]: {
      width: '80%',
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
    },
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '1.5px solid #8e4398',
    margin: '0 auto',
    marginBottom: spacing(2),
  },
  beforeIcon: {},
  message: {
    fontSize: '1.2rem',
    textTransform: 'capitalize',
    width: '90%',
    color: 'rgba(0,0,0,0.7)',
    [breakpoints.up('sm')]: {
      fontSize: '2rem',
      marginTop: spacing(1),
    },
    [breakpoints.up('md')]: {
      fontSize: '2.5rem',
    },
  },
  title: {
    // marginTop: spacing(1),
    // marginBottom: spacing(4),
    color: 'rgba(0,0,0,0.6)',
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      fontSize: '2.5rem',
      marginTop: spacing(2),
    },
    [breakpoints.up('md')]: {
      // marginBottom: spacing(-1),
    },
  },
}));
