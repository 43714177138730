import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette, shadows, shape, breakpoints }) => ({
  root: {
    background: `linear-gradient(to right, ${palette.secondary.dark}, ${palette.primary.main} 90%)`,
    padding: 0,
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: props => (props.scrolled ? shadows[2] : shadows[0]),
  },
  toolbar: {
    justifyContent: 'flex-end',
    flex: 1,
    marginLeft: 0,
    maxWidth: 1050,
    padding: spacing(0, 1.5, 0, 1),
    [breakpoints.up('sm')]: {
      padding: spacing(0, 2),
      margin: '0 auto',
    },
    [breakpoints.up('md')]: {
      padding: spacing(0, 6),
    },
  },
  logoButton: {
    '&&': {
      marginLeft: spacing(1),
      padding: spacing(1, 0, 1, 1),
      borderRadius: shape.borderRadius,
      [breakpoints.up('sm')]: {
        padding: spacing(1.25),
      },
    },
    '& svg': {
      color: '#fff',
    },
  },
  logo: {
    width: '4em',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flex: {
    display: 'flex',
  },
}));
