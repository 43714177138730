import typefaceLight from 'assets/fonts/DINNextRoundedLTPro-Light.otf';
import typefaceRegular from 'assets/fonts/DINNextRoundedLTPro-Regular.otf';
import typefaceMedium from 'assets/fonts/DINNextRoundedLTPro-Medium.otf';
import typefaceBold from 'assets/fonts/DINNextRoundedLTPro-Bold.otf';

const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream);

const tfLight = {
  fontFamily: 'DINNextRoundedLTPro',
  src: `url(${typefaceLight}) format('opentype')`,
  fontWeight: 300,
};
const tfRegular = {
  fontFamily: 'DINNextRoundedLTPro',
  src: `url(${typefaceRegular}) format('opentype')`,
  fontWeight: 400,
};
const tfMedium = {
  fontFamily: 'DINNextRoundedLTPro',
  src: `url(${typefaceMedium}) format('opentype')`,
  fontWeight: 500,
};
const tfBold = {
  fontFamily: 'DINNextRoundedLTPro',
  src: `url(${typefaceBold}) format('opentype')`,
  fontWeight: 700,
};

export default {
  '@font-face': [tfLight, tfRegular, tfMedium, tfBold],
  html: {
    fontSize: '62%',
  },
  body: {
    paddingRight: '0 !important',
    '& .mui-fixed': {
      paddingRight: '0 !important',
    },
  },
  b: {
    fontWeight: 500,
  },
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
  svg: {
    fill: 'currentColor',
    overflow: 'hidden',
  },
  input: {
    fontSize: isIOS ? '16px !important' : 'inherit',
  },
  'input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
};
