import { createAction, createReducer } from '@reduxjs/toolkit';

export const loadNetwork = createAction('branches/loadNetwork');
export const receivedNetwork = createAction('branches/receivedNetwork');

const initialState = {
  loading: true,
  network: [],
  stats: {
    screens: 0,
    visitors: 0,
    avgAd: 0,
  },
};

export default createReducer(initialState, {
  [receivedNetwork]: (state, action) => {
    const { network, stats } = action.payload;
    return {
      ...state,
      loading: false,
      network,
      stats,
    };
  },
});
